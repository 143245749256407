<template>
  <b-row>
    <b-col sm="12">
      <div
        class="birthday-block"
        v-for="(birth, index) in birthdate"
        :key="index"
      >
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ birth.name }}</h4>
          </template>
        </iq-card>
        <b-row class="row">
          <b-col
            class="col-md-6 col-lg-6"
            v-for="(img, index1) in birth.image"
            :key="index1"
          >
            <div class="iq-card">
              <div class="iq-card-body">
                <div class="iq-birthday-block">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="d-flex align-items-center">
                      <a href="#">
                        <img
                          v-if="img.img"
                          :src="img.img"
                          alt="profile-img"
                          class="img-fluid"
                          width="150"
                        />
                        <img
                          v-else
                          src="../../../assets/images/user-icon.svg"
                          alt="profile-img"
                          class="img-fluid"
                          width="150"
                        />
                      </a>
                      <div class="friend-info ml-3">
                        <h5>{{ img.title }}</h5>
                        <p class="mb-0">{{ img.text.substr(0, 6) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { socialvue } from "../../../config/pluginInit";
import axios from "../../../components/axios";
export default {
  name: "Birthdate",
  mounted() {
    var parent = this;
    socialvue.index();
    axios.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");

    const config = {
      method: "get",
      url: "birthday",
    };

    axios(config)
      .then((res) => {
        parent.birthdate = res.data.birthday;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  data() {
    return {
      birthdate: [],
    };
  },
};
</script>
